import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'
import { logout } from '../../../reducers/admin';
import { API_BASE_URL, BASE_URL } from '../../../config';

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const admin = useSelector((state) => state.admin.value);

    const logoutHandler = () => {
        dispatch(logout());
        navigate('/');
    }

    let dateTime = moment().format('MMMM Do YYYY');
    let day = moment().format('dddd'); 


    const [dayDate, setDayDate] = useState('');

    useEffect(() => {
        setDayDate(`${day}, ${dateTime}`);
    }, []);

    return (
        <>
            <div className="posPageContentHeader">
                <div className="posPageContentHeaderPageDate">
                    <p className="posPageContentHeaderPageTxt">dashboard</p>
                    <p className="posPageContentHeaderDateTxt">{dayDate}</p>
                </div>

                <div className="posPageContentHeaderProfileLogout">
                    <div className="posPageContentHeaderProfile">
                        <div className="posPageContentHeaderProfileAvatar">
                            {
                                admin?.profile_pic
                                ?
                                    <img src={`${API_BASE_URL}${admin.profile_pic}`} className="posPageContentHeaderProfileAvatarImg" />
                                :
                                    <img src={`${BASE_URL}images/user.jpg`} className="posPageContentHeaderProfileAvatarImg" />
                            }
                        </div>
                        <div className="posPageContentHeaderProfileDetails">
                            <p className="posPageContentHeaderProfileDetailsNameTxt">{admin.name}</p>
                            <p className="posPageContentHeaderProfileDetailsEmailTxt">{admin.email_id}</p>
                        </div>
                    </div>
                    <div className="posPageContentHeaderLogout">
                        <div className="posPageContentHeaderLogoutMain" onClick={logoutHandler}>
                            <i className="fa-solid fa-power-off posPageContentHeaderLogoutIco"></i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header