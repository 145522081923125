import React, { useState, useEffect, useRef} from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BASIC_ORDER_UPDATED, PRODUCT_ADDED_TO_ORDER, ORDER_PAID, ORDER_DELIVERED, REQUEST_ERROR, ORDER_DELETED } from '../utils/messages';
import { notifyError, notifySuccess, notifyWarning } from '../utils/toastify';
import PlainContainer from '../components/general/containers/PlainContainer';
import Section from '../components/general/containers/Section';import axios from 'axios';
import { useSelector } from 'react-redux';
import { API_BASE_URL, DEFAULT_PAGE_SIZE, FIRST_PAGE, LOGGER } from '../config';

import Table from '../components/dashboard/common/table/Table';
import Heading from '../components/dashboard/common/table/Heading';
import HeadingItem from '../components/dashboard/common/table/HeadingItem';
import TableRow from '../components/dashboard/common/table/TableRow';
import RowItem from '../components/dashboard/common/table/RowItem';
import RowImage from '../components/dashboard/common/table/RowImage';
import RowIcon from '../components/dashboard/common/table/RowIcon';
import TableButton from '../components/dashboard/common/table/TableButton';
import Button from '../components/general/forms/Button';
import IconButton from '../components/general/forms/IconButton';
import Pagination from '../components/general/Pagination';

import moment from 'moment';
import DeliverOrderPopUp from '../components/dashboard/popups/DeliverOrderPopUp';
import MakePaymentPopUp from '../components/dashboard/popups/MakePaymentPopUp';

const StoreOrdersPage = () => {
    const [totalPages, setTotalPages] = useState({});
    const [currentPage, setCurrentPage] = useState(FIRST_PAGE);
    let { id } = useParams(); 
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [orders, setOrders] = useState([]);
    const [showDeliverOrderPopUp, setShowDeliverOrderPopUp] = useState(false);
    const [showMakePaymentPopUp, setShowMakePaymentPopUp] = useState(false);

    const [orderDets, setOrderDets] = useState();

    const getOrders = async (currentPage=FIRST_PAGE,pageSize=DEFAULT_PAGE_SIZE) => {
        try{
            // const getOrdersReq = await axios.get(`${API_BASE_URL}order/store-orders/today?page=${currentPage}&size=${pageSize}`, config);
            const getOrdersReq = await axios.get(`${API_BASE_URL}order/store-orders?page=${currentPage}&size=${pageSize}`, config);
            if(getOrdersReq.status === 200){
                setOrders(getOrdersReq.data.items);
                setTotalPages(getOrdersReq.data.totalPages);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER && console.log(error);
        }
    }

    useEffect(() => {
        getOrders(currentPage);
    }, []);

    const updateThisOrderStatus = async (data, order_id) => {
        try{
            const updateThisOrderStatusReq = await axios.post(`${API_BASE_URL}order/status/${order_id}`, data, config);
            console.log(updateThisOrderStatusReq);
            if (updateThisOrderStatusReq.status === 200) {
                return true;
            }
            else{
                return false;
            }
        }
        catch (error) {
            return false;
        }
    }
    
    const payThisOrder = async (order) => {
        setOrderDets(order);
        setShowMakePaymentPopUp(order);
    }

    const deliverThisOrder = async (order) => {
        const status_data = {
            status: "DELIVERED"
        }

        const isMarkedOrdered = await updateThisOrderStatus(status_data, order.id);
        if(isMarkedOrdered === true){
            notifySuccess(ORDER_DELIVERED);
            getOrders(currentPage);
        }
        else{
            notifyError("Failed to update order status.");
        }
    }

    const deleteOrder = async (order_id) => {
        const status_data = {
            status: "ARCHIVED"
        }

        const isMarkedOrdered = await updateThisOrderStatus(status_data, order_id);
        if(isMarkedOrdered === true){
            notifySuccess(ORDER_DELETED);
            getOrders(currentPage);
        }
        else{
            notifyError("Failed to update order status.");
        }
    }

    const handleConcludeDeliverOrderAction = (status) => {
        if(status === true){
            setShowDeliverOrderPopUp(false);
            getOrders(currentPage);
        }
        else{
            setShowDeliverOrderPopUp(false);
        }
    }

    const handleConcludeMakePaymentAction = (status) => {
        if(status === true){
            setShowMakePaymentPopUp(false);
            getOrders(currentPage);
        }
        else{
            setShowMakePaymentPopUp(false);
        }
    }

    return (
        <>
            <div className="sectionMarginTop">&nbsp;</div>
            <Section heading="Store Orders" description={routeText} action={() => navigate(`/dashboard/orders/create`)} actionLable="create order" showAction={true}>
                <Table>
                    <Heading>
                        <HeadingItem size="1" isIcon={false} icon="" lable={`ID`} />
                        {/* <HeadingItem size="2" isIcon={false} icon="" lable={`store`} /> */}
                        <HeadingItem size="3" isIcon={false} icon="" lable={`cust. name`} />
                        <HeadingItem size="2" isIcon={false} icon="" lable={`phone no.`} />
                        <HeadingItem size="1" isIcon={true} icon="fa-solid fa-boxes-stacked" lable={`products`} />
                        <HeadingItem size="1" isIcon={true} icon="fa-solid fa-indian-rupee-sign" lable={`products`} />
                        <HeadingItem size="2" isIcon={false} icon="" lable={`ordered time`} />
                        <HeadingItem size="1" isIcon={false} icon="" lable={``} />
                        <HeadingItem size="1" isIcon={false} icon="" lable={``} />
                        <HeadingItem size="2" isIcon={false} icon="" lable={`status`} />
                        <HeadingItem size="3" isIcon={false} icon="" lable={`action`} />
                    </Heading>
                    <PlainContainer styles={{width: '100%'}}>
                    {
                        orders.map((order, i) => {
                        let localDate = new Date(order.ordered_at).toLocaleString('en-US', {timeZone: 'Asia/Kolkata'});
                        let displayDate = moment(localDate).format('Do MMM, h:mm a');
                        return (
                            <TableRow key={order.id}>
                                <RowItem size="1" lable={order.order_id} isCenter={true} hasChildren={false}></RowItem>
                                {/* <RowItem size="2" lable={order.store.name} isCenter={false} hasChildren={false}></RowItem> */}
                                <RowItem size="3" lable={order.customer.name} isCenter={false} hasChildren={false}></RowItem>
                                <RowItem size="2" lable={order.customer.phone_number} isCenter={false} hasChildren={false}></RowItem>
                                <RowItem size="1" lable={order.products.length} isCenter={true} hasChildren={false}></RowItem>
                                <RowItem size="1" lable={order.costing.final_price} isCenter={true} hasChildren={false}></RowItem>
                                <RowItem size="2" lable={displayDate} isCenter={false} hasChildren={false}></RowItem>
                                <RowItem size="1" isCenter={false} hasChildren={true}>
                                    <RowIcon name="fa-solid fa-eye" action={() => order.status === "DRAFT" ? navigate(`/dashboard/orders/create/add-product/${order.id}`) : navigate(`${order.id}`)} styles={{cursor: 'pointer'}} />
                                </RowItem>
                                <RowItem size="1" isCenter={false} hasChildren={true}>
                                    <RowIcon name="fa-solid fa-print" action={()=> window.open(`/print/${order.id}`, "_blank")} styles={{cursor: 'pointer'}} />
                                </RowItem>
                                <RowItem size="2" lable="2" isCenter={true} hasChildren={true}>
                                    {
                                        order.status === "ORDERED" && order.is_paid === false
                                        ?
                                            <TableButton styles={{width: '100%'}} action={() => payThisOrder(order)} type="danger">paid</TableButton>
                                        :
                                        order.status === "ORDERED" && order.is_paid === true
                                        ?
                                            <TableButton styles={{width: '100%'}} action={() => deliverThisOrder(order)} type="success">deliver</TableButton>
                                        :
                                        order.status === "DELIVERED"
                                        ?
                                            <TableButton styles={{width: '100%'}} disableClick={true} type="success">delivered</TableButton>
                                        :
                                        order.status === "DRAFT"
                                        ?
                                            <TableButton styles={{width: '100%'}} disableClick={true} type="danger">in draft</TableButton>
                                        :
                                        <></>
                                    }
                                    
                                </RowItem>
                                <RowItem size="3" lable="3" isCenter={false} hasChildren={true}>
                                    {
                                        order.status != "DRAFT"
                                        ?
                                            <>
                                                <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} type="primary" action={() => navigate(`/dashboard/orders/details/${order.id}`)}>view</TableButton>
                                                <TableButton styles={{width: 'calc(50% - 5px)'}} type="danger" action={() => deleteOrder(order.id)}>delete</TableButton>
                                            </>
                                        :
                                        <>
                                            <TableButton styles={{width: 'calc(50% - 5px)', marginRight: '10px'}} type="primary" action={() => navigate(`/dashboard/orders/create/add-product/${order.id}`)}>edit</TableButton>
                                            <TableButton styles={{width: 'calc(50% - 5px)'}} type="danger" action={() => deleteOrder(order.id)}>{order.is_online === false ? 'discard' : 'cancel'}</TableButton>
                                        </>
                                    }
                                </RowItem>
                            </TableRow>
                        )
                        })
                    }
                    </PlainContainer>
                </Table>
                <Pagination totalPages={totalPages} handlePageChange={getOrders} setCurrentPage={setCurrentPage}/>
            </Section>
            {
                showMakePaymentPopUp
                ?
                <MakePaymentPopUp config={config} order={orderDets} concludeMakePaymentPopUp={(status) => handleConcludeMakePaymentAction(status)} />
                :
                <></>
            }
        </>
    )
}

export default StoreOrdersPage