import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { API_BASE_URL, GOOGLE_MAPS_API_KEY } from '../../config';

const Map = ({lat, lng, getUpdatedLatLng}) => {

    const containerStyle = {
        width: '100%',
        height: '300px'
      };


    console.log(lat);
    console.log(lng);

    const center = {
        lat: lat,
        lng: lng
    };

    const locationUpdated = (e) => {
        console.log(e);

        let newLat = e.latLng.lat();
        let newLng = e.latLng.lng();

        const data = {
            lat: newLat,
            lng: newLng
        }

        getUpdatedLatLng(data);
    }

    return (
        <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
            <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16}>
                <Marker position={center} draggable={true} onDragEnd={(e) => locationUpdated(e)}></Marker>
            </GoogleMap>
      </LoadScript>
    );
};
    
export default Map;