import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { showSideBar, hideSideBar } from '../../../reducers/admin';

const Navbar = () => {
    const location = useLocation();
    
    const dispatch = useDispatch();

    const currentRoute = location.pathname.split('/');

    const [activeRoute, setActiveRoute] = useState('');

    useEffect(() => {
        if(currentRoute.length === 2){
            setActiveRoute('dashboard');
        }
        else if(currentRoute.length > 2){
            if(currentRoute[3] === 'create'){
                setActiveRoute('create');
            }
            else if(currentRoute[3] === 'store'){
                setActiveRoute('store');
            }
            else if(currentRoute[2] === 'online-orders'){
                setActiveRoute('online');
            }
            else if(currentRoute[2] === 'accepted-orders'){
                setActiveRoute('accepted');
            }
            else if(currentRoute[2] === 'out-for-delivery-orders'){
                setActiveRoute('out-for-delivery');
            }
            else if(currentRoute[2] === 'delivered-orders'){
                setActiveRoute('delivered');
            }
            else if(currentRoute[2] === 'other-orders'){
                setActiveRoute('other');
            }
            else{
                setActiveRoute('dashboard');
            }
        }
    }, [location]);

    useEffect(() => {
        let tempCurrentRoute = currentRoute;
        tempCurrentRoute.pop();
        let isAddProductPage = tempCurrentRoute.join('/');

        if(isAddProductPage === "/dashboard/orders/create/add-product"){
            dispatch(showSideBar());
        }
        else{
            dispatch(hideSideBar());
        }

    }, [location]);

    return (
        <div className="posPageLeftNav">
            <div className="posPageLeftNavLogo">
                <div className="posPageLeftNavLogoMain">
                    <img src="/images/logo-blackbox-no-bg.png" className="posPageLeftNavLogoImg" />
                </div>
            </div>

            <div className="posPageLeftNavMain">
                <div className="posPageLeftNavMainInner">
                    <Link to={`/dashboard`} className="posPageLeftNavItem">
                        <div className={`posPageLeftNavItemIcon ${activeRoute === 'dashboard' ? 'selected' : ''}`} >
                            <i className="fa-solid fa-house posPageLeftNavItemIco"></i>
                        </div>
                    </Link>
                    <Link to={`/dashboard/orders/create`} className="posPageLeftNavItem">
                        <div className={`posPageLeftNavItemIcon ${activeRoute === 'create' ? 'selected' : ''}`}>
                            <i className="fa-solid fa-cart-plus posPageLeftNavItemIco"></i>
                        </div>
                    </Link>
                    <Link to={`/dashboard/orders/store`} className="posPageLeftNavItem">
                        <div className={`posPageLeftNavItemIcon ${activeRoute === 'store' ? 'selected' : ''}`}>
                            <i className="fa-solid fa-store posPageLeftNavItemIco"></i>
                        </div>
                    </Link>
                    <Link to={`/dashboard/online-orders`} className="posPageLeftNavItem">
                        <div className={`posPageLeftNavItemIcon ${activeRoute === 'online' ? 'selected' : ''}`}>
                            <i className="fa-solid fa-house-laptop posPageLeftNavItemIco"></i>
                        </div>
                    </Link>
                    <Link to={`/dashboard/accepted-orders`} className="posPageLeftNavItem">
                        <div className={`posPageLeftNavItemIcon ${activeRoute === 'accepted' ? 'selected' : ''}`}>
                            <i className="fa-solid fa-check-to-slot posPageLeftNavItemIco"></i>
                        </div>
                    </Link>
                    <Link to={`/dashboard/out-for-delivery-orders`} className="posPageLeftNavItem">
                        <div className={`posPageLeftNavItemIcon ${activeRoute === 'out-for-delivery' ? 'selected' : ''}`}>
                            <i className="fa-solid fa-person-biking posPageLeftNavItemIco"></i>
                        </div>
                    </Link>
                    <Link to={`/dashboard/delivered-orders`} className="posPageLeftNavItem">
                        <div className={`posPageLeftNavItemIcon ${activeRoute === 'delivered' ? 'selected' : ''}`}>
                            <i className="fa-regular fa-thumbs-up posPageLeftNavItemIco"></i>
                        </div>
                    </Link>
                    <Link to={`/dashboard/other-orders`} className="posPageLeftNavItem">
                        <div className={`posPageLeftNavItemIcon ${activeRoute === 'other' ? 'selected' : ''}`}>
                            <i className="fa-solid fa-ban posPageLeftNavItemIco"></i>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Navbar