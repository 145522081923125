import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Heading from '../components/dashboard/common/table/Heading';
import HeadingItem from '../components/dashboard/common/table/HeadingItem';
import RowItem from '../components/dashboard/common/table/RowItem';
import Table from '../components/dashboard/common/table/Table';
import TableRow from '../components/dashboard/common/table/TableRow';
import DetailSection from '../components/dashboard/common/detailSection/DetailSection';
import DetailSectionContent from '../components/dashboard/common/detailSection/DetailSectionContent';
import DetailSectionRow from '../components/dashboard/common/detailSection/DetailSectionRow';
import NoDataContainer from '../components/general/containers/NoDataContainer';
import PlainContainer from '../components/general/containers/PlainContainer';
import Section from '../components/general/containers/Section';
import { API_BASE_URL, LOGGER } from '../config';

import { REQUEST_ERROR } from '../utils/messages';
import { notifyError } from '../utils/toastify';

const StoreOrderDetailsPage = () => {
    let { id } = useParams(); 
    const admin = useSelector((state) => state.admin.value);
    let navigate = useNavigate();
    const location = useLocation();
    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');
  
    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);
  
    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }
  
    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [order, setOrder] = useState();
    const [showOrderDetails, setShowOrderDetails] = useState(false);
    const [showOrderNotCompleted, setShowOrderNotCompleted] = useState(false);
    const [showOrderNotFound, setShowOrderNotFound] = useState(false);
    const [displayDate, setDisplayDate] = useState();

    const getThisOrder = async (order_id) => {
        try{
            const getThisProductReq = await axios.get(`${API_BASE_URL}order/${order_id}`, config);
            if(getThisProductReq.status === 200){
                if(getThisProductReq.data.status != "DRAFT"){
                    setShowOrderNotCompleted(false);
                    setOrder(getThisProductReq.data);
                    setShowOrderDetails(true);
                    let localDate = new Date(getThisProductReq.data.ordered_at).toLocaleString('en-US', {timeZone: 'Asia/Kolkata'});
                    let displayDate = moment(localDate).format('Do MMM, h:mm a');
                    setDisplayDate(displayDate);
                    console.log(getThisProductReq.data);
                }
                else{
                    setShowOrderNotCompleted(true);
                } 
            }
            else{
                console.log()
                //notifyError(REQUEST_ERROR);
                setShowOrderDetails(false);
            }
        }
        catch (error) {
            //notifyError(REQUEST_ERROR);
            setShowOrderDetails(false);
            setShowOrderNotCompleted(false);
            setShowOrderNotFound(true);
            LOGGER && console.log(error);
        }
    }
    
    useEffect(() => {
        getThisOrder(id);
    }, []);

    return (
        <>
            <div className="posPageContentContainerOrder">
                <div className="posPageContentContainerOrderInner">
                    <Section heading="store order details" description={routeText} action={() => navigate("/dashboard/orders/create")} actionLable="create new order" showAction={true}>
                        <PlainContainer type="fullwidth">
                            {
                                showOrderNotCompleted
                                ?
                                <>
                                    <NoDataContainer icon={`fa-regular fa-face-frown`} heading={`Order Yet To Be Completed!`} description={`The order that you are trying to view isn't yet marked complete. Check out this space after sometime.`} isPrimary={true} primaryLable={`view store orders`} primaryAction={()=>navigate(`/dashboard/orders/store-orders`)} primaryType={`button`} isSecondary={true} secondaryLable={`create new order`} secondaryAction={()=>navigate(`/dashboard/orders/create`)} secondaryType={`button`}></NoDataContainer>
                                </>
                                :
                                <></>
                            }
                            {
                                showOrderNotFound
                                ?
                                <>
                                    <NoDataContainer icon={`fa-regular fa-face-frown`} heading={`Order Not Found!`} description={`We are unable to find the order that you are trying to access. Please re-check your order details and come back again.`} isPrimary={true} primaryLable={`view store orders`} primaryAction={()=>navigate(`/dashboard/orders/store-orders`)} primaryType={`button`} isSecondary={true} secondaryLable={`create new order`} secondaryAction={()=>navigate(`/dashboard/orders/create`)} secondaryType={`button`}></NoDataContainer>
                                </>
                                :
                                <></>
                            }
                            {
                                showOrderDetails 
                                ?
                                <>
                                    <DetailSection lable="basic details" hasPrimaryAction={true} lablePrimaryAction={`print`} primaryAction={()=> window.open(`/print/${order.id}`, "_blank")} hasSecondaryAction={false}>
                                        <DetailSectionContent hasPadding={true}>
                                            <DetailSectionRow lable={`order ID`} value={`BLCBOX${order.order_id}`} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`ordered on`} value={displayDate} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`customer name`} value={order.customer.name} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`customer phone no.`} value={order.customer.phone_number} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`store`} value={order.store.name} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`base price`} value={order?.costing.base_price} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`discount`} value={order?.costing.discount} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`discounted_price`} value={order?.costing.discounted_price} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`tax`} value={order?.costing.tax} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`delivery price`} value={order?.costing.delivery_price} isImage={false} isIcon={false} hasChildren={false} />
                                            <DetailSectionRow lable={`final price`} value={order?.costing.final_price} isImage={false} isIcon={false} hasChildren={false} />
                                        </DetailSectionContent>
                                    </DetailSection>

                                    <PlainContainer type={`fullwidth`} styles={{marginTop: '20px'}}>
                                        <DetailSection lable="products" hasPrimaryAction={false} lablePrimaryAction={`back`} primaryAction={``} hasSecondaryAction={false}>
                                            <DetailSectionContent hasPadding={true}>
                                                <Table>
                                                    <Heading>
                                                        <HeadingItem size="1" isIcon={false} icon="" lable={`sl no`} />
                                                        <HeadingItem size="2" isIcon={false} icon="" lable={`category`} />
                                                        <HeadingItem size="3" isIcon={false} icon="" lable={`name`} />
                                                        <HeadingItem size="1" isIcon={false} icon="" lable={`qty`} />
                                                        <HeadingItem size="1" isIcon={false} icon="" lable={`Rs.`} />
                                                    </Heading>
                                                    <PlainContainer styles={{width: '100%'}}>
                                                        {
                                                            order.products.map((product, i) => {
                                                                return (
                                                                    <TableRow key={product.id}>
                                                                        <RowItem size="1" lable={i+1} isCenter={true} hasChildren={false}></RowItem>
                                                                        <RowItem size="2" lable={product.category.name} isCenter={false} hasChildren={false}></RowItem>
                                                                        <RowItem size="3" lable={product.product.name} isCenter={false} hasChildren={false}></RowItem>
                                                                        <RowItem size="1" lable={product.quantity} isCenter={true} hasChildren={false}></RowItem>
                                                                        <RowItem size="1" lable={product.price * product.quantity} isCenter={true} hasChildren={false}></RowItem>
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }
                                                    </PlainContainer>
                                                </Table>
                                            </DetailSectionContent>
                                        </DetailSection>
                                    </PlainContainer>
                                </>
                                :
                                <></>
                            }
                        </PlainContainer>
                    </Section>
                </div>
            </div>
        </>
    )
}

export default StoreOrderDetailsPage