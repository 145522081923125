import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from '../../../utils/toastify';
import axios from 'axios';
import Geocode from "react-geocode";
import Map from '../../general/Map';
import { API_BASE_URL, LOGGER, GOOGLE_MAPS_API_KEY } from '../../../config';

const DeliverOrderPopUp = ({config, order, concludeDeliverOrderPopUp}) => {
    let { id } = useParams(); 

    const [address, setAddress] = useState('');
    const [landmark, setLandmark] = useState('');
    const [pincode, setPincode] = useState('');
    const [latlng, setLatlng] = useState('');

    const [isAddressError, setIsAddressError] = useState(false);
    const [isLandmarkError, setIsLandmarkError] = useState(false);
    const [isPincodeError, setIsPincodeError] = useState(false);
    const [isLatlngError, setIsLatlngError] = useState(false);

    const [addressErrorTxt, setAddressErrorTxt] = useState('');
    const [landmarkErrorTxt, setLandmarkErrorTxt] = useState('');
    const [pincodeErrorTxt, setPincodeErrorTxt] = useState('');
    const [latlngErrorTxt, setLatlngErrorTxt] = useState('');

    const [searchBy, setSearchBy] = useState("ADDRESS");
    const [isSearchByAddress, setIsSearchByAddress] = useState(true);
    const [isAddressSubmitted, setIsAddressSubmitted] = useState(false);

    const [userLatitude, setUserLatitude] = useState();
    const [userLongitude, setUserLongitude] = useState();

    const [showMap, setShowMap] = useState(false);

    const [isPricingGot, setIsPricingGot] = useState(false);

    const [distance, setDistance] = useState(0);

    useEffect(() => {
        console.log(distance);
    }, [distance])

    const handleSearchByChanged = (e) => {
        if(e.target.value === "ADDRESS"){
            setIsSearchByAddress(true);
            setSearchBy("ADDRESS");
        }
        else if(e.target.value === "LATLNG"){
            setIsSearchByAddress(false);
            setSearchBy("LATLNG");
        }
        else{
            setIsSearchByAddress(true);
            setSearchBy("ADDRESS");
        }
    }

    const updateLatLng = async (data) => {
        setUserLatitude(data.lat);
        setUserLongitude(data.lng);
        setIsPricingGot(false);
    }

    const showMapHandler = async () => {

        if(isSearchByAddress === true){
            if(address === ''){
                setAddressErrorTxt('Address is Required');
                setIsAddressError(true);
                return
            }
            else{
                setAddressErrorTxt('');
                setIsAddressError(false);
            }

            if(pincode === ''){
                setPincodeErrorTxt('Pincode is Required');
                setIsPincodeError(true);
                return
            }
            else{
                setPincodeErrorTxt('');
                setIsPincodeError(false);
            }

            Geocode.setApiKey(GOOGLE_MAPS_API_KEY);
            Geocode.setLanguage("en");
            Geocode.setRegion("IN");
            Geocode.enableDebug();
    
            var userLat;
            var userLong;
    
            const geoLocation = await Geocode.fromAddress(address);
    
            const { lat, lng } = geoLocation.results[0].geometry.location;
    
            userLat = lat;
            userLong = lng;
    
            setUserLatitude(userLat);
            setUserLongitude(userLong);
    
            let userLocation = `${lat}, ${lng}`;

            setIsAddressSubmitted(true);
            setShowMap(true);
        }
        else{
            if(latlng === ''){
                setLatlngErrorTxt('Latlng is Required');
                setIsLatlngError(true);
                return
            }
            else{
                setLatlngErrorTxt('');
                setIsLatlngError(false);
            }

            const latlngArr = latlng.replaceAll(/\s/g,'').split(',');

            setUserLatitude(parseFloat(latlngArr[0]));
            setUserLongitude(parseFloat(latlngArr[1]));

            setIsAddressSubmitted(true);
            setShowMap(true);
        }
    }

    const checkTelyportPricing = async (user_location, store_location) => {
        var price_location_data = {
            origin: store_location,
            destination: user_location
        }

        var tely_config = {
            headers: { 'Content-Type': 'application/json' }
        };

        const pricing = await axios.post(`https://telyport.com/api/v2/beta/pricing`, price_location_data, tely_config);
        if(pricing.status === 200){
            return pricing.data;
        }
        else{
            return false;
        }
    }

    const confirmDeliveryLocationHandler = async () => {
        if(isPricingGot === false){
            const store_location = {
                lat: parseFloat(order.store.latlong.lat),
                lng: parseFloat(order.store.latlong.lng),
            }

            const user_location = {
                lat: userLatitude,
                lng: userLongitude
            }

            const storeLocationLatLng = `${store_location.lat}, ${store_location.lng}`;
            const userLocationLatLng = `${user_location.lat}, ${user_location.lng}`

            const deliveryPricing = await checkTelyportPricing(userLocationLatLng, storeLocationLatLng);
            if(deliveryPricing != false){
                setDistance(deliveryPricing.distance);
                setIsPricingGot(true);
            }
            else{
                alert('error in getting pricing');
            }
        }
        else{

            const deliveryPricePerKm = 12;

            const getDeliveryPrice = () => {
                const price = distance > 3 ? deliveryPricePerKm*distance - deliveryPricePerKm*3 : 0;
                return Math.round(parseFloat(price.toFixed(2)))
            }

            const getAddressHandler = async () => {
                try{
                    const getAddressReq = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${userLatitude},${userLongitude}&sensor=true&key=${GOOGLE_MAPS_API_KEY}`);
                    if(getAddressReq.status === 200){
                        return getAddressReq.data.results[0].formatted_address
                    }
                }
                catch(error){
                    return false
                }
            }

            const data = {
                distance: distance,
                package_size: "SMALL",
                delivery_price: getDeliveryPrice(),
                name: order.customer.name,
                address: isSearchByAddress ? `${address}, ${pincode}` : await getAddressHandler(),
                landmark: landmark,
                latitute: userLatitude,
                longitude: userLongitude,
                delivery_type: "DELIVERY"
            }
            console.log(data);

            //console.log(`https://maps.google.com/maps?q=${userLatitude},${userLongitude}`);
            updateThisOrderDeliveryType(data);
        }
    }

    const updateThisOrderDeliveryType = async (data) => {
        try{
            const updateThisOrderDeliveryTypeReq = await axios.post(`${API_BASE_URL}order/delivery-type/${order.id}`, data, config);
            console.log(updateThisOrderDeliveryTypeReq);
            if (updateThisOrderDeliveryTypeReq.status === 200) {
                notifySuccess("Delivery type successfully updated.");
                reset();
                concludeDeliverOrderPopUp(true);
                LOGGER && console.log(updateThisOrderDeliveryTypeReq);
            }
            else{
                notifyError("Failed to update delivery type.");
                LOGGER && console.log(updateThisOrderDeliveryTypeReq);
            }
        }
        catch (error) {
            console.log(error);
        }
        
    }

    const reset = () => {
        setAddress('');
        setIsAddressError(false);
        setAddressErrorTxt('');

        setLandmark('')
        setIsLandmarkError(false);
        setLandmarkErrorTxt('');

        setPincode('')
        setIsPincodeError(false);
        setPincodeErrorTxt('');

        setLatlng('')
        setIsLatlngError(false);
        setLatlngErrorTxt('');

        setIsAddressSubmitted(false);
        setShowMap(false);

        setUserLatitude(0);
        setUserLongitude(0);

        setIsPricingGot(false);
    }

    const editAddressHandler = () => {
        setShowMap(false);
    }

    return (
        <>
            <div className="posPopUp">
                <div className="posPopUpOverlay">&nbsp;</div>
                <div className="posPopUpMain">
                    <div className="posPopUpInner">
                        <div className="posPopUpHeading">
                            <p className="posPopUpHeadingTxt">Deliver Order</p>
                        </div>

                        <div className="posPopUpContent">
                            <div className="posPopUpContentMain">
                                <div className="posPopUpContentInner">
                                    {
                                        showMap === false
                                        ?
                                        <>
                                            <div className="posPageFormMain">
                                                <div className="posPageFormStep">
                                                    <label className="form-control">
                                                        <input type="radio" name="radio" defaultChecked={searchBy === "ADDRESS" ? true : false} value={"ADDRESS"} onChange={(e) => handleSearchByChanged(e)} />
                                                        Enter Address
                                                    </label>
                                                    <label className="form-control">
                                                        <input type="radio" name="radio" defaultChecked={searchBy === "LATLNG" ? true : false} value={"LATLNG"} onChange={(e) => handleSearchByChanged(e)} />
                                                        Enter Latlng
                                                    </label>
                                                </div>
                                                {
                                                    isSearchByAddress
                                                    ?
                                                    <>
                                                        <div className="posPageFormStep">
                                                            <div className="posPageFormStepLable">
                                                                <p className="posPageFormStepLableTxt">address</p>
                                                            </div>
                                                            <div className="posPageFormStepInput">
                                                                <textarea className="posPageFormStepInputTxtarea" rows={3} placeholder="Please Enter The Customer Address" onChange={(e) => setAddress(e.target.value)} defaultValue={address}></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="posPageFormStep">
                                                            <div className="posPageFormStepLable">
                                                                <p className="posPageFormStepLableTxt">landmark</p>
                                                            </div>
                                                            <div className="posPageFormStepInput">
                                                                <input type="text" className="posPageFormStepInputTxt" placeholder="Enter The Landmark" value={landmark} onChange={(e) => setLandmark(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="posPageFormStep">
                                                            <div className="posPageFormStepLable">
                                                                <p className="posPageFormStepLableTxt">pincode</p>
                                                            </div>
                                                            <div className="posPageFormStepInput">
                                                                <input type="tel" className="posPageFormStepInputTxt" placeholder="Enter The Pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="posPageFormStep">
                                                        <div className="posPageFormStepLable">
                                                            <p className="posPageFormStepLableTxt">latlng</p>
                                                        </div>
                                                        <div className="posPageFormStepInput">
                                                            <input type="text" className="posPageFormStepInputTxt" placeholder="Enter The Latlng" value={latlng} onChange={(e) => setLatlng(e.target.value)} />
                                                        </div>
                                                    </div>
                                                }
                                                
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="posPageFormMain">
                                                <Map lat={userLatitude} lng={userLongitude} getUpdatedLatLng={(e) => { updateLatLng(e) }}></Map>

                                                {
                                                    isPricingGot
                                                    ?
                                                    <div className="deliverOrderPopUpDistance">
                                                        <div className="deliverOrderPopUpDistanceInner">
                                                            <p className="deliverOrderPopUpDistanceLable">Distance:</p>
                                                            <p className="deliverOrderPopUpDistanceStat">{distance} KMS</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <></>
                                                }
                                                
                                            </div>
                                        </>
                                    }
                                    
                                </div>
                            </div>

                            {
                                isAddressSubmitted === false
                                ?
                                <div className="posPopUpContentActions">
                                    <div className="posPopUpContentActionsInner">
                                        <button className="posPopUpContentActionSingle" onClick={() => console.log(false)}>close</button>
                                        <button className="posPopUpContentActionSingle" onClick={() => showMapHandler()}>confirm</button>
                                    </div>
                                </div>
                                :
                                <div className="posPopUpContentActions">
                                    <div className="posPopUpContentActionsInner">
                                        <button className="posPopUpContentActionSingle" onClick={() => editAddressHandler()}>edit address</button>
                                        {
                                            isPricingGot === false
                                            ?
                                            <button className="posPopUpContentActionSingle" onClick={() => confirmDeliveryLocationHandler()}>confirm location</button>
                                            :
                                            <button className="posPopUpContentActionSingle" onClick={() => confirmDeliveryLocationHandler()}>confirm</button>
                                        }
                                    </div>
                                </div>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeliverOrderPopUp