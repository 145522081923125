import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";

import { add, remove } from '../reducers/order';
import { BASIC_ORDER_UPDATED, PRODUCT_ADDED_TO_ORDER, REQUEST_ERROR } from '../utils/messages';
import { notifyError, notifySuccess, notifyWarning } from '../utils/toastify';
import { API_BASE_URL, LOGGER } from '../config';
import CustomizeProductPopUp from '../components/dashboard/popups/CustomizeProductPopUp';
import { enableReloadOrder } from '../reducers/admin';

const AddProductsToNewOrderPage = () => {
    let { id } = useParams(); 
    const admin = useSelector((state) => state.admin.value);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const location = useLocation();

    const slider = useRef(null);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [products, setProducts] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState();
    const [showCustomizePopUp, setShowCustomizePopUp] = useState(false);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    let category_slider = {
        dots: false,
        infinite: false,
        speed: 200,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows : false
    };

    const getCategories = async () => {
        try{
            const getCategoriesReq = await axios.get(`${API_BASE_URL}category?sort=1`);
            if(getCategoriesReq.status === 200){
                const temp_categories = [];

                let all_category = {
                    name: 'all',
                    order: 0,
                    id: 0,
                    icon: 'fa-solid fa-boxes-stacked'
                }

                temp_categories.push(all_category);

                getCategoriesReq.data.items.map(thisCat => {
                    temp_categories.push(thisCat);
                });

                setCategories(temp_categories);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER && console.log(error);
        }
    }

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        getThisCategoryProductsHandler(selectedCategory);
    }, [categories]);

    const getThisCategoryProductsHandler = async (categoryId) => {
        try{
            const getThisCategoryProductsReq = await axios.get(`${API_BASE_URL}product/category/${categoryId}`, config);
            if(getThisCategoryProductsReq.status === 200){
                setProducts(getThisCategoryProductsReq.data)
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER && console.log(error);
        }
    }
    
    const changeSelectedCategoryHandler = async (categoryId) => {
        setSelectedCategory(categoryId);
        getThisCategoryProductsHandler(categoryId);
    }

    const addThisProductHandler = (product) => {
        console.log(product);
        setSelectedProduct(product);

        if(product.sizes.length > 0){
            setShowCustomizePopUp(true);
        }
        else{
            const product_data = {
                product: product.id,
                category: product.category.id,
                quantity: 1,
                price: product.price.final.store
            }

            saveThisProductToOrder(product_data);
        }
    }

    const saveThisProductToOrder = async (product_data) => {
        try{
            const addProductToOrderReq = await axios.post(`${API_BASE_URL}order/add-product/${id}`, product_data, config);
            console.log(addProductToOrderReq);
            if (addProductToOrderReq.status === 200) {
                LOGGER && console.log(addProductToOrderReq);
                dispatch(enableReloadOrder());
            }
            else{
                notifyError("Failed to add product to order.");
                LOGGER && console.log(addProductToOrderReq);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleCustomizeProductAction = (status) => {
        setShowCustomizePopUp(false);
        dispatch(enableReloadOrder());
    }



    return (
        <>
            <div className="posPageContentContainer">
                <div className="posPageContentContainerCategories">
                    <div className="posPageContentContainerCategoriesMain">
                        <Slider ref={slider} {...category_slider}>
                            {
                                categories.map((category, i) => {
                                    return (
                                        <div className="posPageContentContainerCategory" key={category.id}>
                                            <div className={`posPageContentContainerCategoryMain ${selectedCategory === category.id ? 'selected' : <></>}`} onClick={() => changeSelectedCategoryHandler(category.id)}>
                                                <i className={`${category.icon} posPageContentContainerCategoryIco`}></i>
                                                <p className="posPageContentContainerCategoryTxt">{category.name}</p>
                                            </div>
                                        </div>
                                        
                                    )
                                })
                            }
                        </Slider>
                    </div>
                    <div className="posPageContentContainerCategoriesArrows">
                        <div className="posPageContentContainerCategoriesArrowSingle" onClick={() => slider?.current?.slickPrev()}>
                            <i className="fa-solid fa-chevron-left posPageContentContainerCategoriesArrowSingleIco"></i>
                        </div>
                        <div className="posPageContentContainerCategoriesArrowSingle" onClick={() => slider?.current?.slickNext()}>
                            <i className="fa-solid fa-chevron-right posPageContentContainerCategoriesArrowSingleIco"></i>
                        </div>
                    </div>
                </div>

                <div className="posPageContentContainerSearch">
                    <input className="posPageContentContainerSearchInput" placeholder="Search product by name" />
                </div>

                <div className="posPageContentContainerMenu">
                    {
                        products.map((product, i) => {
                            return (
                                <div className="posPageContentContainerMenuItem" onClick={() => addThisProductHandler(product)} key={product.id}>
                                    <div className="posPageContentContainerMenuItemInner">
                                        <div className="posPageContentContainerMenuItemAvatar">
                                            <img src="/images/quarterly-pizza-non-veg.png" className="posPageContentContainerMenuItemAvatarImg" alt="product" />
                                        </div>
                                        <div className="posPageContentContainerMenuItemDetails">
                                            <p className="posPageContentContainerMenuItemNameTxt">{product.name}</p>
                                            <div className="posPageContentContainerMenuItemCost">
                                                <div className="posPageContentContainerMenuItemCostRupee">
                                                    <i className="fa-solid fa-indian-rupee-sign posPageContentContainerMenuItemCostRupeeIco"></i>
                                                </div>
                                                <p className="posPageContentContainerMenuItemCostTxt">{product.price.final.store}</p>
                                            </div>
                                        </div>

                                        <div className="posPageContentContainerMenuItemCart">
                                            <i className="fa-solid fa-cart-shopping posPageContentContainerMenuItemCartIco"></i>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

            {
                showCustomizePopUp ?
                    <CustomizeProductPopUp product={selectedProduct} config={config} completeCustomization={(status) => handleCustomizeProductAction(status)} />
                :
                    <></>
            }
        </>
    )
}

export default AddProductsToNewOrderPage