import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isExpired, decodeToken, useJwt } from "react-jwt";
import { ToastContainer } from 'react-toastify';

import Footer from '../components/general/Footer';
import { validateEmail, validatePassword } from '../utils/validation';
import { API_BASE_URL, JWT_SECRET, LOGGER, BASE_URL, DEFAULT_IP_ADDRESS } from '../config';
import { login } from '../reducers/admin';
import { notifyError, notifySuccess } from '../utils/toastify';
import { ADMIN_LOGIN_FAILED, ADMIN_LOGIN_SUCCESS, REQUEST_ERROR } from '../utils/messages';

const LoginPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
  
    const admin = useSelector((state) => state.admin.value);
  
    const [emailID, setEmailID] = useState("");
    const [password, setPassword] = useState("");
  
    const [isEmailError, setIsEmailError] = useState(false);
    const [isPasswordError, setIsPasswordError] = useState(false);
  
    const [emailErrorTxt, setEmailErrorTxt] = useState("");
    const [passwordErrorTxt, setPasswordErrorTxt] = useState("");
  
    const [isInvalidAdmin, setIsInvalidAdmin] = useState(false);
    const [isRoleVerified, setRoleVerified] = useState(true);
  
    const [loading, setLoading] = useState(false);

    const submitLoginForm = async (e) => {
        e.preventDefault();
        setLoading(true); 
    
        console.log(emailID);
        console.log(password);
    
        let isEmailValid = validateEmail(emailID);
        if(isEmailValid === false){
            setIsEmailError(true);
            setEmailErrorTxt("Enter a Valid Email ID");
            return;
        }
        else{
            setIsEmailError(false);
            setEmailErrorTxt("");
        }
    
    
        let isPasswordValid = validatePassword(password);
        if(isPasswordValid === false){
            setIsPasswordError(true);
            setPasswordErrorTxt("Enter a Valid Password");
            return;
        }
        else{
            setIsPasswordError(false);
            setPasswordErrorTxt("");
        }
    
        const admin = {
            email_id: emailID,
            password: password
        }
        console.log(admin);
    
        try{
            const tryLogin = await axios.post(`${API_BASE_URL}staff/login`, admin);
            const status = tryLogin.status;
            if (status === 200) {
                const data = tryLogin.data;
                const {role} = decodeToken(data.access_token, JWT_SECRET);
                if (role === 'ADMIN' || role === 'SUPER_ADMIN' || role === 'STAFF') {
                    dispatch(login({ id: data.admin_id, name: data.name, role: role, email_id: data.email_id, access_token: data.access_token, refresh_token: data.refresh_token, profile_pic: data?.profile_pic, is_logged_in: true, show_order_sidebar: false }));
                    navigate('/dashboard');
                } 
                else {
                    setRoleVerified(false);
                }
                setLoading(false);
            }
            else {
                setLoading(false);
                setIsInvalidAdmin(true);
                notifyError(ADMIN_LOGIN_FAILED);
            }
        }
        catch (error){
            notifyError(ADMIN_LOGIN_FAILED);
            setLoading(false);
            setIsInvalidAdmin(true);
            LOGGER && console.log(error);
        }
    }    
    
    const resetForm = () => {
        setEmailID("");
        setPassword("");
        setIsEmailError(false);
        setIsPasswordError(false);
        setEmailErrorTxt("");
        setPasswordErrorTxt("");
    }

    return (
        <>
            <div className="loginPage">
                <div className="loginPageContent">
                    <div className="loginPageContentLogo">
                        <div className="loginPageContentLogoInner">
                            <img src="/images/logo-blackbox-no-bg.png" className="loginPageContentLogoImg" alt="Blackbox Pizza" />
                        </div>
                    </div>

                    <div className="loginPageContentForm">
                        <div className="loginPageContentFormHeading">
                            <p className="loginPageContentFormHeadingTxt">login</p>
                        </div>
                        
                        <form onSubmit={(e) => submitLoginForm(e)} noValidate>
                            <div className="loginPageContentFormMain">
                                <div className="loginPageContentFormStep">
                                    <div className="loginPageContentFormStepLable">
                                        <p className="loginPageContentFormStepLableTxt">email ID</p>
                                    </div>
                                    <div className="loginPageContentFormStepInput">
                                        <input type="email" className="loginPageContentFormStepInputTxt" placeholder="Enter Your Email ID" onChange={(e) => setEmailID(e.target.value)} value={emailID}  />
                                    </div>
                                </div>
                                <div className="loginPageContentFormStep">
                                    <div className="loginPageContentFormStepLable">
                                        <p className="loginPageContentFormStepLableTxt">password</p>
                                    </div>
                                    <div className="loginPageContentFormStepInput">
                                        <input type="password" className="loginPageContentFormStepInputTxt" placeholder="Enter Your Password"onChange={(e) => setPassword(e.target.value)} value={password} />
                                    </div>
                                </div>
                                <div className="loginPageContentFormStep">
                                    <button type="submit" className="loginPageContentFormStepSubmit">submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
        
    )
}

export default LoginPage