import { createSlice } from "@reduxjs/toolkit";

const initialValue = {id: '', name: '', role: '', email_id: '', access_token: '', refresh_token: '', profile_pic: '', is_logged_in: true, show_order_sidebar: true, reload_order: false};

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        value: initialValue
    },
    reducers: {
        login: (state, action) => {
            state.value = action.payload;
        },
        showSideBar: (state, action) => {
            state.value.show_order_sidebar = true;
        },
        hideSideBar: (state, action) => {
            state.value.show_order_sidebar = false;
        },
        enableReloadOrder: (state, action) => {
            state.value.reload_order = true;
        },
        disableReloadOrder: (state, action) => {
            state.value.reload_order = false;
        },
        logout: (state) => {
            state.value = initialValue;
        }
    }
});

export const { login, logout, showSideBar, hideSideBar, enableReloadOrder, disableReloadOrder } = adminSlice.actions;

export default adminSlice.reducer;