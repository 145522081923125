/**
 * DEV DEPENDENCIES
 */

// export const API_BASE_URL = "http://localhost:4041/";
// export const BASE_URL = "/";
// export const SOCKET_URL = "http://localhost:4041";
// export const BASE_LINK = "/";
// export const SOCKET_PATH = "/";
// export const DEFAULT_IP_ADDRESS = "127.0.0.1";
// export const GOOGLE_MAPS_API_KEY = "AIzaSyAEa1uvbs1m8u4OsFnEIqVwY2Ho3vDJhWY";

/**
* PROD DEPENDENCIES
*/

 export const API_BASE_URL = "https://api.blackbox.pizza/";
 export const BASE_URL = "/";
 export const SOCKET_URL = "https://api.blackbox.pizza";
 export const BASE_LINK = "/";
 export const SOCKET_PATH = "/";
 export const DEFAULT_IP_ADDRESS = "127.0.0.1";
 export const GOOGLE_MAPS_API_KEY = "AIzaSyAEa1uvbs1m8u4OsFnEIqVwY2Ho3vDJhWY";


/**
 * COMMON FOR BOTH DEV & PROD
 */
export const AUTHOR_NAME = "LookitLive";
export const BRAND_NAME = "Blackbox Pizza";
export const JWT_SECRET = "boxLive";
export const FIRST_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const LOGGER = process.env.NODE_ENV === 'development';
export const ROLES = {
   SUPER_ADMIN: 'SUPER_ADMIN',
   ADMIN: 'ADMIN',
   STAFF: 'STAFF'
}