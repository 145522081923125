import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notifyError, notifySuccess } from '../../../utils/toastify';
import axios from 'axios';
import { API_BASE_URL, LOGGER } from '../../../config';
import { REQUEST_ERROR } from '../../../utils/messages';

const ApplyCouponPopUp = ({config, order, concludeCouponPopUp}) => {
    console.log(order);

    const [coupon, setCoupon] = useState('');

    const [isCouponError, setIsCouponError] = useState(false);

    const [couponErrorTxt, setCouponErrorTxt] = useState('');

    const submitCouponForm = async (e) => {
        e.preventDefault();

        if(coupon === ''){
            setIsCouponError(true);
            setCouponErrorTxt('Coupon is Required');
            return
        }
        else{
            setIsCouponError(false);
            setCouponErrorTxt('');
        }

        const data = {
            order: order.id,
            coupon: coupon
        }

        console.log(data);

        try {
            const applyCouponReq = await axios.post(`${API_BASE_URL}coupon/store/apply`, data, config);
            console.log(applyCouponReq);
            if(applyCouponReq.status === 200){
                concludeCouponPopUp(true);
                LOGGER &&  console.log(applyCouponReq);
            }
        }
        catch (error) {
            console.log(error);
            if(error.response.status === 403){
                notifyError(error.response.data.message);
            }
            else if(error.response.status === 404){
                notifyError(error.response.data.message);
            }
            else{
                console.log(error.response.status)
                notifyError(REQUEST_ERROR);
            }
        }
    }

    return (
        <>
            <div className="posPopUp">
                <div className="posPopUpOverlay">&nbsp;</div>
                <div className="posPopUpMain">
                    <div className="posPopUpInner">
                        <div className="posPopUpHeading">
                            <p className="posPopUpHeadingTxt">Apply Coupon Code</p>
                        </div>

                        <div className="posPopUpContent">
                            <form onSubmit={(e) => submitCouponForm(e)} noValidate>
                                <div className="posPopUpContentMain">
                                    <div className="posPopUpContentInner">
                                        <div className="loginPageContentFormStep">
                                            <div className="loginPageContentFormStepLable">
                                                <p className="loginPageContentFormStepLableTxt">coupon code *</p>
                                            </div>
                                            <div className="loginPageContentFormStepInput">
                                                <input type="text" className="loginPageContentFormStepInputTxt" placeholder="Enter Your Coupon code" onChange={(e) => setCoupon(e.target.value)} value={coupon}  />
                                            </div>
                                            {
                                                isCouponError
                                                ?
                                                <div className="loginPageContentFormStepError">
                                                    <p className="loginPageContentFormStepErrorTxt">{couponErrorTxt}</p>
                                                </div>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="posPopUpContentActions">
                                    <div className="posPopUpContentActionsInner">
                                        <button className="posPopUpContentActionSingle" type="button" onClick={() => concludeCouponPopUp(false)}>close</button>
                                        <button className="posPopUpContentActionSingle" type="submit">confirm</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApplyCouponPopUp