import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_BASE_URL, LOGGER } from '../config';
import { notifyError, notifySuccess, notifyWarning } from '../utils/toastify';
import { BASIC_ORDER_CREATED, CUSTOMER_AVAILABLE, CUSTOMER_CREATED, CUSTOMER_NOT_AVAILABLE, REQUEST_ERROR } from '../utils/messages';

const AddCustomerToNewOrderPage = () => {
    const admin = useSelector((state) => state.admin.value);

    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [stores, setStores] = useState([]);
    const [store, setStore] = useState('0');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [name, setName] = useState('');
    const [customer, setCustomer] = useState();
    const [isCustomerFound, setIsCustomerFound] = useState(false);
    const [showEnterName, setShowEnterName] = useState(false);

    const [isStoreError, setIsStoreError] = useState(false);
    const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);
    const [isNameError, setIsNameError] = useState(false);
    const [isCustomerError, setIsCustomerError] = useState(false);

    const [storeErrorTxt, setStoreErrorTxt] = useState('');
    const [phoneNumberErrorTxt, setPhoneNumberErrorTxt] = useState('');
    const [nameErrorTxt, setNameErrorTxt] = useState('');

    const userNameRef = useRef(null);

    const getStores = async () => {
        try{
            const getStoresReq = await axios.get(`${API_BASE_URL}store`);
            if(getStoresReq.status === 200){
                setStores(getStoresReq.data.items);
            }
        }
        catch (error) {
            notifyError(REQUEST_ERROR);
            LOGGER && console.log(error);
        }
    }

    useEffect(() => {
        getStores();
    }, []);

    const checkCustomerAvailability = async () => {
        try {
            const checkCustomerPhoneReq = await axios.get(`${API_BASE_URL}customer/phone/${phoneNumber}`, config);
            if(checkCustomerPhoneReq.status === 200) {
                LOGGER && console.log(checkCustomerPhoneReq.data);
                notifySuccess(CUSTOMER_AVAILABLE)
                setCustomer(checkCustomerPhoneReq.data);
                createBasicOrder(checkCustomerPhoneReq.data.id);
            }
        }
        catch (error) {
            if (error.response.status === 404) {
                notifyWarning(CUSTOMER_NOT_AVAILABLE);
                setShowEnterName(true);
                // userNameRef.current.handleFocus();
            }
            else{
                notifyError(REQUEST_ERROR);
            }
        }
    }

    const registerUser = async (e) => {
        const data = {
            name: name,
            phone_number: phoneNumber
        }
    
        try {
            const registerCustomerReq = await axios.post(`${API_BASE_URL}customer`, data, config);
            if (registerCustomerReq.status === 201) {
                notifySuccess(CUSTOMER_CREATED);
                createBasicOrder(registerCustomerReq.data.customer_id);
            }
        }
        catch (error) {
            if(error.response.status === 404){
                setShowEnterName(true);
            }
            else{
                notifyError(REQUEST_ERROR);
            }
        }
    }

    const createBasicOrder = async (customer_id) => {
        const customerData = {
            customer_id: customer_id,
            store: store,
            created_by: admin.id
        }
    
        LOGGER && console.log(customerData);
    
        try {
            const createBasicOrderReq = await axios.post(`${API_BASE_URL}order/basic`, customerData, config);
            if (createBasicOrderReq.status === 201) {
                notifySuccess(BASIC_ORDER_CREATED);
                navigate(`/dashboard/orders/create/add-product/${createBasicOrderReq.data.id}`);
            }
        }
        catch (error) {
            LOGGER && console.log(error);
            notifyError(REQUEST_ERROR);
        }
    }

    const submitCreateBasicOrderForm = (e) => {
        e.preventDefault();

        if(store === '0'){
            alert('Select store');
            return
        }

        if(phoneNumber === ""){
            alert('Enter Phone Number');
            return
        }
        else if(phoneNumber.length != 10){
            alert('Enter a Valid 10 Digit Phone Number');
            return
        }

        if(name != ''){
            return registerUser();
        }
        if(isCustomerFound != true){
            checkCustomerAvailability();
        }
    }

    const addProduct = () => {
        navigate('add-product/1');
    }

    return (
        <>
            <div className="posPageContainer">
                <div className="posPageContainerHeadings">
                    <p className="posPageContainerHeadingTxt">Hi, Welcome To Blackbox Pizza.</p>
                    <p className="posPageContainerSubHeadingTxt">Manage Orders of Blackbox Pizza In One Dashboard!</p>
                </div>

                <div className="posPageSection">
                    <div className="posPageSectionHeadings">
                        <p className="posPageSectionHeadingTxt">Create Order</p>
                        <p className="posPageSectionSubHeadingTxt">{routeText}</p>
                    </div>

                    <div className="posPageSectionContent">
                        <form onSubmit={(e) => submitCreateBasicOrderForm(e)} noValidate>
                            <div className="posPageFormMain">
                                <div className="posPageFormStep">
                                    <div className="posPageFormStepLable">
                                        <p className="posPageFormStepLableTxt">store</p>
                                    </div>
                                    <div className="posPageFormStepInput">
                                        <select className="posPageFormStepInputSelect" onChange={(e) => setStore(e.target.value)}>
                                            <option value="0" className="posPageFormStepInputSelectOption">Please Select The Store</option>
                                            {
                                                stores.map((store, i) => {
                                                    return (
                                                        <option value={store.id} key={store.id} className="posPageFormStepInputSelectOption">{store.name}</option>
                                                    )
                                                })   
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="posPageFormStep">
                                    <div className="posPageFormStepLable">
                                        <p className="posPageFormStepLableTxt">mobile number</p>
                                    </div>
                                    <div className="posPageFormStepInput">
                                        <input type="tel" className="posPageFormStepInputTxt" placeholder="Enter Mobile Number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                    </div>
                                </div>
                                {
                                    showEnterName === true
                                    ?
                                    <div className="posPageFormStep">
                                        <div className="posPageFormStepLable">
                                            <p className="posPageFormStepLableTxt">name</p>
                                        </div>
                                        <div className="posPageFormStepInput">
                                            <input type="text" ref={userNameRef} className="posPageFormStepInputTxt" placeholder="Please Enter The Customer Name" onChange={(e) => setName(e.target.value)} value={name} />
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                                <div className="posPageFormStep">
                                    <button type="submit" className="posPageFormStepSubmit">submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCustomerToNewOrderPage