import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { useSelector } from 'react-redux';
import OrderSidebar from './OrderSidebar';
import Footer from '../../general/Footer';
import Header from './Header';
import { ToastContainer } from 'react-toastify';

const Layout = ({children}) => {

    const admin = useSelector((state) => state.admin.value);

    return (
        <>
            <div className="posPage">
                <Navbar />
                <div className={`posPageContent ${admin.show_order_sidebar ? `hasSidebar` : ''}`}>
                    <div className="posPageContentMain">
                        <Header />
                        {children}
                        {
                            admin.show_order_sidebar ?
                            <OrderSidebar />
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
            <ToastContainer style={{ zIndex: "99999 !important" }} />
            <Footer />
        </>
    )
}

export default Layout