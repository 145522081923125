import React, { useState, useEffect } from 'react';
import { notifyError, notifySuccess } from '../../../utils/toastify';
import axios from 'axios';
import { API_BASE_URL, LOGGER } from '../../../config';
import { REQUEST_ERROR } from '../../../utils/messages';

const MakePaymentPopUp = ({config, order, concludeMakePaymentPopUp}) => {
    console.log(config);
    console.log(order);
    const [mode, setMode] = useState('CASH');

    const changeSelectionHandler = (type) => {
        setMode(type);
    }

    const submitMakePaymentForm = async (e) => {
        e.preventDefault();

        const data = {
            status: "PAID",
            mode: mode
        }

        console.log(data);

        try {
            const makePaymentReq = await axios.post(`${API_BASE_URL}order/pay/${order.id}`, data, config);
            console.log(makePaymentReq);
            if(makePaymentReq.status === 200){
                concludeMakePaymentPopUp(true);
                LOGGER &&  console.log(makePaymentReq);
            }
        }
        catch (error) {
            console.log(error);
            notifyError(REQUEST_ERROR);
        }
    }

    return (
        <>
            <div className="posPopUp">
                <div className="posPopUpOverlay">&nbsp;</div>
                <div className="posPopUpMain">
                    <div className="posPopUpInner">
                        <div className="posPopUpHeading">
                            <p className="posPopUpHeadingTxt">Select Mode of Payment</p>
                        </div>

                        <div className="posPopUpContent">
                            <form onSubmit={(e) => submitMakePaymentForm(e)} noValidate>
                                <div className="posPopUpContentMain">
                                    <div className="posPopUpContentInner">
                                        <div className="loginPageContentFormStep">
                                            <div className="loginPageContentFormStepLable">
                                                <p className="loginPageContentFormStepLableTxt">Mode of Payment *</p>
                                            </div>
                                            <div className="loginPageContentFormStepPayModes">
                                                <div className={mode === "CASH" ? "posPopUpContentPaymentModeSingle selected" : "posPopUpContentPaymentModeSingle"} onClick={() => changeSelectionHandler("CASH")}>
                                                    <p className="posPopUpContentPaymentModeSingleTxt">Cash</p>
                                                </div>
                                                <div className={mode === "CARD" ? "posPopUpContentPaymentModeSingle selected" : "posPopUpContentPaymentModeSingle"} onClick={() => changeSelectionHandler("CARD")}>
                                                    <p className="posPopUpContentPaymentModeSingleTxt">Card</p>
                                                </div>
                                                <div className={mode === "UPI" ? "posPopUpContentPaymentModeSingle selected" : "posPopUpContentPaymentModeSingle"} onClick={() => changeSelectionHandler("UPI")}>
                                                    <p className="posPopUpContentPaymentModeSingleTxt">UPI</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="posPopUpContentActions">
                                    <div className="posPopUpContentActionsInner">
                                        <button className="posPopUpContentActionSingle" type="button" onClick={() => concludeMakePaymentPopUp(false)}>close</button>
                                        <button className="posPopUpContentActionSingle" type="submit">confirm</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )       
}

export default MakePaymentPopUp