export const REQUEST_ERROR = "Something went wrong";

export const ADMIN_LOGIN_FAILED = "Invalid Email ID or Password";
export const ADMIN_LOGIN_SUCCESS = "Login Successfull";

// orders
export const ORDER_CREATED = "Order placed successfully";
export const ORDER_UPDATED = "Order updated successfully";
export const PRODUCT_ADDED_TO_ORDER = "Product successfully added to order";
export const PRODUCT_DELETED_FROM_ORDER = "Product deleted from order";
export const ORDER_CONFIRMED = "Order confirmed successfully";
export const ORDER_DELETED = "Order deleted successfully";
export const ORDER_PAID = "Payment received successfully";
export const ORDER_DELIVERED = "Order delivered successfully";
export const ORDER_INACTIVATED = "Order inactivated successfully";
export const ORDER_ACTIVATED = "Order activated successfully";
export const ORDER_ACCEPTED = "Order accepted successfully";
export const ORDER_REJECTED = "Order rejected successfully";
export const ORDER_CANCELLED = "Order cancelled successfully";
export const SELECT_DELIVERY_BOY = "Please select a delivery boy";
export const ORDER_SENT = "Order sent successfully";
export const CANNOT_DELIVER_WITHOUT_PAYMENT = "Order cannot be delivered before accepting payment";


// orders
export const BASIC_ORDER_CREATED = "Basic order created successfully";
export const BASIC_ORDER_UPDATED = "Basic order updated successfully";

//OTP
export const OTP_VALIDATED = "OTP validated successfully";


//Order Item
export const ITEM_ADDED = "Item added successfully";

// Customer 
export const CUSTOMER_CREATED = "Customer created successfully";
export const CUSTOMER_UPDATED = "Customer updated successfully";
export const CUSTOMER_DELETED = "Customer deleted successfully";
export const CUSTOMER_INACTIVATED = "Customer inactivated successfully";
export const CUSTOMER_ACTIVATED = "Customer activated successfully";
export const CUSTOMER_AVAILABLE = "Customer available";
export const CUSTOMER_NOT_AVAILABLE = "Customer not available";
export const CUSTOMER_CANT_BE_ACTIVATED = "Can't activate the user before validating the phone no.";