import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_BASE_URL, LOGGER } from '../config';
import { REQUEST_ERROR } from '../utils/messages';
import { notifyError } from '../utils/toastify';

const DashboardPage = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const [routeArray, setRouteArray] = useState(location.pathname.substring(1).split('/'));
    const [routeText, setRouteText] = useState('');

    useEffect(() => {
        let tempRoute = '';
        routeArray.map(route => {
            tempRoute = tempRoute.concat(`${route} / `);
            tempRoute = tempRoute.replace('-', ' ');
        });
        setRouteText(removeLastChars(tempRoute, 3));
    }, []);

    let removeLastChars = (str, chars) => {
        return str.substring(0, str.length - chars);
    }

    const admin = useSelector((state) => state.admin.value);

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;

    const [newOrders, setNewOrders] = useState(0);
    const [orders, setOrders] = useState(0);
    const [revenue, setRevenue] = useState(0);
    const [cancellations, setCancellations] = useState(0);

    const getOrderStats = async () => {

        console.log(today);

        const data = {
            from: today,
            to: today
        }

        try{
            const getOrderStatsReq = await axios.post(`${API_BASE_URL}order/revenue-range`, data, config);
            if (getOrderStatsReq.status === 200) {
                setOrders(parseInt(getOrderStatsReq.data.totalOrders));
                setRevenue(parseFloat(getOrderStatsReq.data.totalRevenue));
                setCancellations(parseInt(getOrderStatsReq.data.totalCancellations));
                setNewOrders(parseInt(getOrderStatsReq.data.newOrders));
            }
        }
        catch(error) {
            notifyError(REQUEST_ERROR);
            LOGGER &&  console.log(error);
        }
    }

    useEffect(() => {
        getOrderStats();
    }, []);

    return (
        <>
            <div className="posPageContainer">
                <div className="posPageContainerHeadings">
                    <p className="posPageContainerHeadingTxt">Hi, Welcome To Blackbox Pizza.</p>
                    <p className="posPageContainerSubHeadingTxt">Manage Orders of Blackbox Pizza In One Dashboard!</p>
                </div>

                <div className="posPageContainerStats">
                    <div className="posPageContainerStatSingle">
                        <div className="posPageContainerStatSingleStat">
                            <p className="posPageContainerStatSingleStatTxt">{newOrders}</p>
                        </div>
                        <div className="posPageContainerStatSingleStatLable">
                            <p className="posPageContainerStatSingleStatLableTxt">new orders</p>
                        </div>
                    </div>
                    <div className="posPageContainerStatSingle">
                        <div className="posPageContainerStatSingleStat">
                            <p className="posPageContainerStatSingleStatTxt">{orders}</p>
                        </div>
                        <div className="posPageContainerStatSingleStatLable">
                            <p className="posPageContainerStatSingleStatLableTxt">total orders</p>
                        </div>
                    </div>
                    <div className="posPageContainerStatSingle">
                        <div className="posPageContainerStatSingleStat">
                            <p className="posPageContainerStatSingleStatTxt">{revenue}</p>
                        </div>
                        <div className="posPageContainerStatSingleStatLable">
                            <p className="posPageContainerStatSingleStatLableTxt">total revenue</p>
                        </div>
                    </div>
                    <div className="posPageContainerStatSingle">
                        <div className="posPageContainerStatSingleStat">
                            <p className="posPageContainerStatSingleStatTxt">{cancellations}</p>
                        </div>
                        <div className="posPageContainerStatSingleStatLable">
                            <p className="posPageContainerStatSingleStatLableTxt">total cancellations</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardPage