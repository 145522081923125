import React from 'react'

const Footer = () => {
    return (
        <div className="footer">
            <p className="footerCopyright">Copyright &copy; 2023, All rights reserved Blackbox Pizza.</p>
            <p className="footerPoweredBy">Powered By: <a className="footerPoweredByHighlight" href="https://www.lookitlive.com" target="_blank">LookitLive</a></p>
        </div>
    )
}

export default Footer