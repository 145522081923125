import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useSelector } from 'react-redux';

import verifyToken from './verify-jwt';
import { ROLES, LOGGER } from './config';

import RootOutlet from './pages/RootOutlet';
import DefaultOutlet from './components/general/DefaultOutlet';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import AddCustomerToNewOrderPage from './pages/AddCustomerToNewOrderPage';
import AddProductsToNewOrderPage from './pages/AddProductsToNewOrderPage';
import StoreOrdersPage from './pages/StoreOrders';
import StoreOrderDetailsPage from './pages/StoreOrderDetails';

const AppRouter = () => {

    const [auth, setAuth] = useState(null);
  
    const admin = useSelector((state) => state.admin.value);
    
    const isAdmin = ROLES.ADMIN === admin.role;
    const isSuperAdmin = ROLES.SUPER_ADMIN === admin.role;
    const isStaff = ROLES.STAFF === admin.role;

    useEffect(() => {
        async function checkIsLoggedIn() {
            if(admin.is_logged_in === true){
                const checkToken = await verifyToken(admin.access_token);
                setAuth(checkToken.status);
            }
            else{
                setAuth(false);
            }
        }
        checkIsLoggedIn();
    }, [admin]);

    return (
        <>
            {
                auth != null
                ?
                <Routes>
                {!auth && (
                    <Route path="/" element={<LoginPage />} />
                )}
                {auth && (
                    <>
                        <Route path="/" element={<Navigate to={"/dashboard"} />} />
                        <Route path="/dashboard" element={<RootOutlet />}>
                            <Route index element={<DashboardPage />} />
                            <Route path="orders" element={<DefaultOutlet />}>
                                <Route path='create' element={<DefaultOutlet />}>
                                    <Route index element={<AddCustomerToNewOrderPage />} />
                                    <Route path='add-product/:id' element={<AddProductsToNewOrderPage />} />
                                </Route>
                                <Route path='store' element={<DefaultOutlet />}>
                                    <Route index element={<StoreOrdersPage />} />
                                    <Route path=':id' element={<StoreOrderDetailsPage />} />
                                </Route>
                            </Route>
                        </Route>
                    </>
                )}
                {/* <Route path="/404" element={<div>404</div>} /> */}
                <Route path="*" element={<Navigate to={auth ? "/dashboard" : "/"} />} />
                </Routes>
                :
                <></>
            }
        </>
    );
  };
  
  export default AppRouter;
  