import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { BASIC_ORDER_UPDATED, PRODUCT_ADDED_TO_ORDER, REQUEST_ERROR } from '../../../utils/messages';
import { notifyError, notifySuccess, notifyWarning } from '../../../utils/toastify';
import { API_BASE_URL, LOGGER } from '../../../config';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { disableReloadOrder } from '../../../reducers/admin';
import CookingInstruction from '../order/CookingInstruction';
import DeliverOrderPopUp from '../../../components/dashboard/popups/DeliverOrderPopUp';
import ApplyCouponPopUp from '../../../components/dashboard/popups/ApplyCouponPopUp';
import MakePaymentPopUp from '../popups/MakePaymentPopUp';

const OrderSidebar = () => {
    let { id } = useParams(); 
    const admin = useSelector((state) => state.admin.value);
    const orderState = useSelector((state) => state.order.value);

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const config = {
        headers: {
            'Authorization': admin.access_token
        }
    }

    const [order, setOrder] = useState();
    const [showOrderDetails, setShowOrderDetails] = useState(false);
    const [showLoadingOrderDetails, setShowLoadingOrderDetails] = useState(false);
    const [showOrderDetailsNotFound, setShowOrderDetailsNotFound] = useState(false);
    const [showOrderNotInDraft, setShowOrderNotInDraft] = useState(false);
    const [showPaymentDetails, setShowPaymentDetails] = useState(true);

    const [orderType, setOrderType] = useState("DINE_IN");

    const [showDeliverOrderPopUp, setShowDeliverOrderPopUp] = useState(false);
    const [showApplyCouponPopUp, setShowApplyCouponPopUp] = useState(false);
    const [showMakePaymentPopUp, setShowMakePaymentPopUp] = useState(false);

    useEffect(() => {
        setOrderType(orderState.delivery_type);
    }, [orderState]);

    const getThisOrder = async (order_id) => {
        try{
            const getThisOrderReq = await axios.get(`${API_BASE_URL}order/${order_id}`, config);
            if(getThisOrderReq.status === 200){
                if(getThisOrderReq.data.status == "DRAFT"){
                    console.log(getThisOrderReq.data);
                    setOrder(getThisOrderReq.data);
                    setOrderType(getThisOrderReq.data.delivery_type);
                    setShowLoadingOrderDetails(false);
                    setShowOrderDetailsNotFound(false);
                    setShowOrderNotInDraft(false);
                    setShowOrderDetails(true);
                }
                else{
                    setShowLoadingOrderDetails(false);
                    setShowOrderDetailsNotFound(false);
                    setShowOrderDetails(false);
                    setShowOrderNotInDraft(true);
                }
            }
            else{
                setShowOrderDetails(false);
                setShowLoadingOrderDetails(false);
                setShowOrderNotInDraft(false);
                setShowOrderDetailsNotFound(true);
            }
            dispatch(disableReloadOrder());
        }
        catch (error) {
            setShowOrderDetails(false);
            setShowLoadingOrderDetails(false);
            setShowOrderNotInDraft(false);
            setShowOrderDetailsNotFound(true);
        }
    }

    useEffect(() => {
        if(admin.reload_order === true){
            getThisOrder(order.id);
        }
    }, [admin]);

    const updateThisOrderDeliveryType = async (data) => {
        try{
            const updateThisOrderDeliveryTypeReq = await axios.post(`${API_BASE_URL}order/delivery-type/${order.id}`, data, config);
            console.log(updateThisOrderDeliveryTypeReq);
            if (updateThisOrderDeliveryTypeReq.status === 200) {
                notifySuccess("Delivery type successfully updated.");
                getThisOrder(order.id);
                LOGGER && console.log(updateThisOrderDeliveryTypeReq);
            }
            else{
                notifyError("Failed to update delivery type.");
                LOGGER && console.log(updateThisOrderDeliveryTypeReq);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const orderTypeChanged = (data) => {
        if(data == "DINE_IN"){
            const delivery_type_data = {
                delivery_type: "DINE_IN"
            }
            updateThisOrderDeliveryType(delivery_type_data);
        }
        else if(data == "TAKEAWAY"){
            const delivery_type_data = {
                delivery_type: "TAKEAWAY"
            }
            updateThisOrderDeliveryType(delivery_type_data);
        }
        else if(data == "DELIVERY"){
            console.log(data);
            setShowDeliverOrderPopUp(true);
        }
        else{
            return
        }
    }

    useEffect(() => {
        getThisOrder(id);
    }, []);
    

    const updateProductQuantityHandler = async (product, quantity) => {
        if(quantity != 0){
            console.log(id);
            const data = {
                product: product,
                quantity: quantity
            }

            try{
                const updateQuantityReq = await axios.patch(`${API_BASE_URL}order/product/quantity/${id}`, data, config);
                if(updateQuantityReq.status === 200){
                    getThisOrder(id);
                }
                else{
                    notifyError("Failed to update quantity.");
                    LOGGER && console.log(updateQuantityReq);
                }
            }
            catch(error) {
                notifyError("Failed to update quantity.");
                LOGGER && console.log(error);
            }
        }
    }

    const removeProductHandler = async (product) => {
        console.log(product);
        const data = {
            product: product
        }

        try{
            const removeProductReq = await axios.patch(`${API_BASE_URL}order/product/remove/${id}`, data, config);
            if(removeProductReq.status === 200){
                getThisOrder(id);
            }
            else{
                notifyError("Failed to remove product.");
                LOGGER && console.log(removeProductReq);
            }
        }
        catch(error) {
            notifyError("Failed to remove product.");
            LOGGER && console.log(error);
        }
    }

    const instructionSavedHandler = async (status) => {
        console.log(status);
        if(status === true){
            getThisOrder(id);
        }
    }

    const clearProductInstructionHandler = async (product) => {
        const data = {
            product: product,
            cooking_instruction: ""
        }

        try{
            const updateInstructionReq = await axios.patch(`${API_BASE_URL}order/product/instruction/${id}`, data, config);
            if(updateInstructionReq.status === 200){
                getThisOrder(id);
            }
            else{
                notifyError("Failed to update instruction.");
                LOGGER && console.log(updateInstructionReq);
            }
        }
        catch(error) {
            notifyError("Failed to update quantity.");
            LOGGER && console.log(error);
        }
    }

    const handleConcludeDeliverOrderAction = (status) => {
        if(status === true){
            setShowDeliverOrderPopUp(false);
            getThisOrder(order.id);
        }
        else{
            setShowDeliverOrderPopUp(false);
        }
    }

    const handleConcludeCouponAction = (status) => {
        if(status === true){
            setShowApplyCouponPopUp(false);
            getThisOrder(order.id);
        }
        else{
            setShowApplyCouponPopUp(false);
        }
    }

    const handleConcludeMakePaymentAction = (status) => {
        if(status === true){
            setShowMakePaymentPopUp(false);
            navigate("/dashboard/orders/store")
        }
        else{
            setShowMakePaymentPopUp(false);
        }
    }

    return (
        <div className="posPageSidebar">
            <div className="posPageSidebarInner">
                <div className="posPageSidebarHeading">
                    <p className="posPageSidebarHeadingTxt">order details</p>
                </div>
                <div className="posPageSidebarCustomerDetails">
                    <div className="posPageSidebarCustomerAvatarName">
                        <div className="posPageSidebarCustomerAvatar">
                            <img src="/images/user.jpg" className="posPageSidebarCustomerAvatarImg" alt="customer" />
                        </div>
                        <p className="posPageSidebarCustomerName">{order?.customer.name}</p>
                    </div>
                    <div className="posPageSidebarCustomerOrderNo">
                        <div className="posPageSidebarCustomerOrderNoLable">
                            <p className="posPageSidebarCustomerOrderNoLableTxt">order #:</p>
                        </div>
                        <div className="posPageSidebarCustomerOrderNoVal">
                            <p className="posPageSidebarCustomerOrderNoValTxt">BLCBOX{order?.order_id}</p>
                        </div>
                    </div>
                </div>
                <div className="posPageSidebarOrderTypes">
                    <div className={`posPageSidebarOrderType ${orderType === "DINE_IN" ? 'selected' : ''}`} onClick={() => orderTypeChanged("DINE_IN")}>
                        <p className="posPageSidebarOrderTypeLable">dine in</p>
                    </div>
                    <div className={`posPageSidebarOrderType ${orderType === "TAKEAWAY" ? 'selected' : ''}`} onClick={() => orderTypeChanged("TAKEAWAY")}>
                        <p className="posPageSidebarOrderTypeLable">takeaway</p>
                    </div>
                    <div className={`posPageSidebarOrderType ${orderType === "DELIVERY" ? 'selected' : ''}`} onClick={() => orderTypeChanged("DELIVERY")}>
                        <p className="posPageSidebarOrderTypeLable">delivery</p>
                    </div>
                </div>
                <div className={`posPageSidebarCurrentOrder ${showPaymentDetails ? 'hasPadding' : ''}`}>
                    <div className="posPageSidebarCurrentOrderHeadingButton">
                        <div className="posPageSidebarCurrentOrderHeading">
                            <p className="posPageSidebarCurrentOrderHeadingTxt">current order</p>
                        </div>
                        <div className="posPageSidebarCurrentOrderButton" onClick={() => setShowPaymentDetails(showPaymentDetails ? false : true)}>
                            <p className="posPageSidebarCurrentOrderButtonTxt">{ showPaymentDetails ? `hide payment info` : `show payment info` }</p>
                        </div>
                    </div>
                    <div className="posPageSidebarCurrentOrderItems">
                        {
                            order?.products.slice(0).reverse().map((product, i) => {
                                return (
                                    <div className="posPageSidebarCurrentOrderItem" key={product.id}>
                                        <div className="posPageSidebarCurrentOrderItemInfoQty">
                                            <div className="posPageSidebarCurrentOrderItemInfo">
                                                <div className="posPageSidebarCurrentOrderItemInfoAvatar">
                                                    <div className="posPageSidebarCurrentOrderItemInfoAvatarMain">
                                                        <img src="/images/quarterly-pizza-non-veg.png" className="posPageSidebarCurrentOrderItemInfoAvatarImg" alt="product" />
                                                    </div>
                                                </div>
                                                <div className="posPageSidebarCurrentOrderItemInfoDetails">
                                                    <p className="posPageSidebarCurrentOrderItemInfoNameTxt">{product.product.name}</p>
                                                    {
                                                        product.category.name === "pizza"
                                                        ?
                                                        <p className="posPageSidebarCurrentOrderItemInfoSizeBaseTxt">{product.size.name}, {product.size.crust.name}</p>
                                                        :
                                                        <></>
                                                    }
                                                    <div className="posPageSidebarCurrentOrderItemInfoCatPrice">
                                                        <p className="posPageSidebarCurrentOrderItemInfoCat">{product.category.name}</p>
                                                        <i className="fa-solid fa-circle posPageSidebarCurrentOrderItemInfoSeprator"></i>
                                                        <div className="posPageSidebarCurrentOrderItemInfoPriceDets">
                                                            <p className="posPageSidebarCurrentOrderItemInfoPriceDetsQtyTxt">{product.quantity}</p>
                                                            <i className="fa-solid fa-xmark posPageSidebarCurrentOrderItemInfoPriceDetsMltIco"></i>
                                                            <div className="posPageSidebarCurrentOrderItemInfoPriceDetsMain">
                                                                <i className="fa-solid fa-indian-rupee-sign posPageSidebarCurrentOrderItemInfoPriceDetsRupeeIco"></i>
                                                                <p className="posPageSidebarCurrentOrderItemInfoPriceDetsRupeeTxt">{product.price}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="posPageSidebarCurrentOrderItemQty">
                                                    <div className="posPageSidebarCurrentOrderItemQtyInner">
                                                        <div className="posPageSidebarCurrentOrderItemQtyButton" onClick={() => updateProductQuantityHandler(product.id, product.quantity - 1)}>
                                                            <i className="fa-solid fa-minus posPageSidebarCurrentOrderItemQtyButtonIco"></i>
                                                        </div>
                                                        <div className="posPageSidebarCurrentOrderItemQtyVal">
                                                            <p className="posPageSidebarCurrentOrderItemQtyValTxt">{product.quantity}</p>
                                                        </div>
                                                        <div className="posPageSidebarCurrentOrderItemQtyButton" onClick={() => updateProductQuantityHandler(product.id, product.quantity + 1)}>
                                                            <i className="fa-solid fa-plus posPageSidebarCurrentOrderItemQtyButtonIco"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="posPageSidebarCurrentOrderItemInstrActions">
                                            <CookingInstruction order={id} product={product.id} instruction={product.cooking_instruction} config={config} instruction_saved={(status) => instructionSavedHandler(status)} />
                                            <div className="posPageSidebarCurrentOrderItemActions">
                                                <div className="posPageSidebarCurrentOrderItemActionSingle" onClick={() => clearProductInstructionHandler(product.id)}>
                                                    <i className="fa-solid fa-xmark posPageSidebarCurrentOrderItemActionSingleIco"></i>
                                                </div>
                                                <div className="posPageSidebarCurrentOrderItemActionSingle" onClick={() => removeProductHandler(product.id)}>
                                                    <i className="fa-solid fa-trash-can posPageSidebarCurrentOrderItemActionSingleIco"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                {
                    showPaymentDetails
                    ?
                    <div className="posPageSidebarOrderPricingActions">
                        <div className="posPageSidebarOrderPricing">
                            <div className="posPageSidebarOrderPricingStep">
                                <div className="posPageSidebarOrderPricingStepLable">
                                    <p className="posPageSidebarOrderPricingStepLableTxt">base price</p>
                                    <div className="posPageSidebarOrderPricingStepSeperator">
                                        <p className="posPageSidebarOrderPricingStepSeperatorTxt">:</p>
                                    </div>
                                </div>
                                <div className="posPageSidebarOrderPricingStepVal">
                                    <div className="posPageSidebarOrderPricingStepValInner">
                                        <i className="fa-solid fa-indian-rupee-sign posPageSidebarOrderPricingStepValIco"></i>
                                        <p className="posPageSidebarOrderPricingStepValTxt">{order?.costing.base_price}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="posPageSidebarOrderPricingStep">
                                <div className="posPageSidebarOrderPricingStepLable">
                                    <p className="posPageSidebarOrderPricingStepLableTxt">discount</p>
                                    <div className="posPageSidebarOrderPricingStepSeperator">
                                        <p className="posPageSidebarOrderPricingStepSeperatorTxt">:</p>
                                    </div>
                                </div>
                                <div className="posPageSidebarOrderPricingStepVal">
                                    <div className="posPageSidebarOrderPricingStepValInner">
                                        <i className="fa-solid fa-indian-rupee-sign posPageSidebarOrderPricingStepValIco"></i>
                                        <p className="posPageSidebarOrderPricingStepValTxt">{order?.costing.discount}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="posPageSidebarOrderPricingStep">
                                <div className="posPageSidebarOrderPricingStepLable">
                                    <p className="posPageSidebarOrderPricingStepLableTxt">discounted price</p>
                                    <div className="posPageSidebarOrderPricingStepSeperator">
                                        <p className="posPageSidebarOrderPricingStepSeperatorTxt">:</p>
                                    </div>
                                </div>
                                <div className="posPageSidebarOrderPricingStepVal">
                                    <div className="posPageSidebarOrderPricingStepValInner">
                                        <i className="fa-solid fa-indian-rupee-sign posPageSidebarOrderPricingStepValIco"></i>
                                        <p className="posPageSidebarOrderPricingStepValTxt">{order?.costing.discounted_price}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="posPageSidebarOrderPricingStep">
                                <div className="posPageSidebarOrderPricingStepLable">
                                    <p className="posPageSidebarOrderPricingStepLableTxt">tax</p>
                                    <div className="posPageSidebarOrderPricingStepSeperator">
                                        <p className="posPageSidebarOrderPricingStepSeperatorTxt">:</p>
                                    </div>
                                </div>
                                <div className="posPageSidebarOrderPricingStepVal">
                                    <div className="posPageSidebarOrderPricingStepValInner">
                                        <i className="fa-solid fa-indian-rupee-sign posPageSidebarOrderPricingStepValIco"></i>
                                        <p className="posPageSidebarOrderPricingStepValTxt">{order?.costing.tax}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="posPageSidebarOrderPricingStep">
                                <div className="posPageSidebarOrderPricingStepLable">
                                    <p className="posPageSidebarOrderPricingStepLableTxt">delivery price</p>
                                    <div className="posPageSidebarOrderPricingStepSeperator">
                                        <p className="posPageSidebarOrderPricingStepSeperatorTxt">:</p>
                                    </div>
                                </div>
                                <div className="posPageSidebarOrderPricingStepVal">
                                    <div className="posPageSidebarOrderPricingStepValInner">
                                        <i className="fa-solid fa-indian-rupee-sign posPageSidebarOrderPricingStepValIco"></i>
                                        <p className="posPageSidebarOrderPricingStepValTxt">{order?.costing.delivery_price}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="posPageSidebarOrderPricingStep">
                                <div className="posPageSidebarOrderPricingStepLable">
                                    <p className="posPageSidebarOrderPricingStepLableTotalTxt">total</p>
                                    <div className="posPageSidebarOrderPricingStepSeperator">
                                        <p className="posPageSidebarOrderPricingStepSeperatorTotalTxt">:</p>
                                    </div>
                                </div>
                                <div className="posPageSidebarOrderPricingStepVal">
                                    <div className="posPageSidebarOrderPricingStepValInner">
                                        <i className="fa-solid fa-indian-rupee-sign posPageSidebarOrderPricingStepValTotalIco"></i>
                                        <p className="posPageSidebarOrderPricingStepValTotalTxt">{order?.costing.final_price}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="posPageSidebarOrderActions">
                            <div className="posPageSidebarOrderDiscountAction" onClick={() => setShowApplyCouponPopUp(true)}>
                                <i className="fa-solid fa-percent posPageSidebarOrderDiscountActionIco"></i>
                            </div>
                            <div className="posPageSidebarOrderMakePaymentAction" onClick={() => setShowMakePaymentPopUp(true)}>
                                <p className="posPageSidebarOrderDiscountActionTxt">make payment</p>
                            </div>
                            <div className="posPageSidebarOrderMultiActions">
                                <div className="posPageSidebarOrderPrintAction">
                                    <i className="fa-solid fa-print posPageSidebarOrderPrintActionTxt"></i>
                                </div>
                                <div className="posPageSidebarOrderDiscardAction">
                                    <i className="fa-solid fa-trash-can posPageSidebarOrderDiscardActionTxt"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
                }

                {
                    showDeliverOrderPopUp
                    ?
                    <DeliverOrderPopUp config={config} order={order} concludeDeliverOrderPopUp={(status) => handleConcludeDeliverOrderAction(status)} />
                    :
                    <></>
                }

                {
                    showApplyCouponPopUp
                    ?
                    <ApplyCouponPopUp config={config} order={order} concludeCouponPopUp={(status) => handleConcludeCouponAction(status)} />
                    :
                    <></>
                }

                {
                    showMakePaymentPopUp
                    ?
                    <MakePaymentPopUp config={config} order={order} concludeMakePaymentPopUp={(status) => handleConcludeMakePaymentAction(status)} />
                    :
                    <></>
                }
                
            </div>
        </div>
    )
}

export default OrderSidebar