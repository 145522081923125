import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { notifyError } from '../../../utils/toastify';
import { API_BASE_URL, LOGGER } from '../../../config';

const CookingInstruction = ({order, product, instruction, config, instruction_saved}) => {

    useEffect(() => {
        setNewInstruction(instruction);
    }, [instruction]);

    const [newInstruction, setNewInstruction] = useState(instruction);

    const saveInstructionHandler = async () => {
        console.log(newInstruction);

        const data = {
            product: product,
            cooking_instruction: newInstruction
        }

        try{
            const updateInstructionReq = await axios.patch(`${API_BASE_URL}order/product/instruction/${order}`, data, config);
            if(updateInstructionReq.status === 200){
                instruction_saved(true);
            }
            else{
                notifyError("Failed to update instruction.");
                LOGGER && console.log(updateInstructionReq);
            }
        }
        catch(error) {
            notifyError("Failed to update quantity.");
            LOGGER && console.log(error);
        }
    }

    return (
        <div className="posPageSidebarCurrentOrderItemInstr">
            <input type="text" className="posPageSidebarCurrentOrderItemInstrTxt" placeholder="Cooking Instruction" value={newInstruction} onChange={(e) => setNewInstruction(e.target.value)} />
            {
                newInstruction != instruction 
                ?
                <div className="posPageSidebarCurrentOrderItemInstrSave" onClick={saveInstructionHandler}>
                    <div className="posPageSidebarCurrentOrderItemInstrSaveInner">
                        <i className="fa-solid fa-check posPageSidebarCurrentOrderItemInstrSaveIco"></i>
                    </div>
                </div>
                :
                <></>
            }
            
        </div>
    )
}

export default CookingInstruction