import { createSlice } from "@reduxjs/toolkit";

const initialValue = {};

export const orderSlice = createSlice({
    name: 'order',
    initialState: {
        value: initialValue
    },
    reducers: {
        add: (state, action) => {
            state.value = action.payload;
        },
        remove: (state) => {
            state.value = initialValue;
        }
    }
});

export const { add, remove } = orderSlice.actions;

export default orderSlice.reducer;